<template lang="pug">
  div.PartnerOrderCRM
    CModal(title="История изменений", color="success", :centered="true", :show.sync="isModalVisible", size="xl")
      div(v-for="(item, i) in history", v-if="i===0 || history[i].status!==history[i-1].status")
        div {{item.username}} / {{item.created_at.replace("T","")}}
        div {{item.comment}}
        div Статус заявки: [{{item.status}}] {{getStatus(item.status)}}
        hr
    .wrap_box
      .wrap_header
        h1.title Заказ №{{order.id}}
        .wrap_header__links
          //button.button_green(type='button', @click="isModalVisible=true")
            span Основная информация
          button.button_green_border(type='button', @click="isModalVisible = true")
            span История изменений
          //button.button_green_border(type='button')
            span Карта партнера
      .wrap_content
        .wrap_content__grafic
          .line
            .line_active(:style="'width:'+ ((((order.status-statuses[0].id)/statuses.length) + (1/statuses.length)/2)*100 ) +'%'")
          .steps
            div.stepN(v-for="status in statuses", :class="status.id===order.status ? 'active' : ''")
              span {{status.sname}}


        CCard(v-for="tstatus in statuses", :key="tstatus.id", v-if="order.status === tstatus.id && tstatus.roles_edit.includes(role)")
          CCardBody
            //div {{tstatus.button_comment === null ? '' : tstatus.button_comment}}
            CButton(color="danger", size="info", v-if="(role==='Manager' || role==='Admin' || role==='RegDir' || role==='BuyManager') && order.status > 1 && order.status < 16", @click="addComment(1)") Отправить на повторное согласование
            CButton(color="danger", size="info", v-if="(role==='Manager' || role==='Admin' || role==='RegDir' || role==='BuyManager') && order.status > 16", @click="addComment(16)") Отправить на повторное согласование

            CButton(color="success", size="info", @click="addComment(tstatus.next_status)") {{tstatus.button_name}}

        .wrap_content__row
          .wrap_table.fx-1
            //.wrap_table__header
              .title Товары в заказе
              //button.button(type='button')
              //  span + Добавить товар

            //.wrap_table__box
              .items
                .item
                  b Название
                  b Цвет
                  b Валюта
                  b Цена единицы
                  b Количество
                  b Общая сумма
                  b Комментарий
                .item
                  span Название
                  span Цвет
                  span Валюта
                  span Цена единицы
                  span Количество
                  span Общая сумма
                  span Комментарий
                .item
                  span Название
                  span Цвет
                  span Валюта
                  span Цена единицы
                  span Количество
                  span Общая сумма
                  span Комментарий
                .item
                  span Название
                  span Цвет
                  span Валюта
                  span Цена единицы
                  span Количество
                  span Общая сумма
                  span Комментарий
                .item
                  span Название
                  span Цвет
                  span Валюта
                  span Цена единицы
                  span Количество
                  span Общая сумма
                  span Комментарий

            vuecrud.crudtable(v-if="role!=='Guest' && order.status > 0 && statuses.length > 0", host="http://wild", ref="maintable", selectedtable = "client_order_goods", :columnsupdated="columnsupdated", :opts="customOpts()", style="margin-top:15px")
              template(v-slot:popupHeader="binds")
                div.datatable-modal-container(style="background-color:#3c4b64; color: white; padding: 16px")
                  h5.modal-title ДОБАВЛЕНИЕ ТОВАРА
              template(v-slot:AddButton)
                button.AddButton(type='button', style="margin-bottom: 10px")
                  span Добавить товар
              template(v-slot:Buttons)
                div
                  CButton(@click="exportTo()", class="AddButton" style="width: fit-content; color: #fff;font-weight: bold; font-size: 1.125rem; margin-left: 10px; margin-bottom: 7px") Выгрузить в Excel
                div
                  form(v-if="(role!=='MainUser')", v-on:submit.prevent="uploadFile", id="uploadFile" method="post" enctype="multipart/form-data" style="height: 100%;display: flex;align-content: center;margin: 0 25px;")
                    input(id="fileUpload" type="file" required style="margin: auto;")
                    CButton(type="submit" class="AddButton" style="width: fit-content; color: #fff;font-weight: bold; font-size: 1.125rem; margin-left: 10px; margin-bottom: 7px") Загрузить Excel
                      div
                h2(style="display: inline-block; flex-grow: 2; text-align: right") Товары в заказе
          .left

            .wrap_table.fx-5(v-show="role !== 'MainUser'")
              .wrap_table__header
                .title Заказчик
              .wrap_table__box
                ul.list
                  li
                    span Партнер:
                    span {{order_partner.name}}
                  li
                    span Контактное лицо:
                    span {{order_user.name}}
                  li
                    span Номер телефона:
                    span {{order_user.tel}}
            .wrap_table(v-bind:class="role !== 'MainUser' ?  'fx-5' :  'fx-3'")
              .wrap_table__header
                .title Информация о заявке
              .wrap_table__box
                ul.list
                  li(v-if="history.length>0")
                    span Заявка создана:
                    span {{history[0].created_at}}
                  li(v-if="history.length>0")
                    span Всего дней в работе:
                    span.text-red {{Math.ceil((new Date()-new Date(history[0].created_at))/(1000 * 60 * 60 * 24))}}
                  li
                    span Страна:
                    span {{getCountry(order.type)}}
                  li
                    span Валюта:
                    span {{getCurrency(order.currency)}}
                  li
                    span Курс:
                    span {{order.course}}
          .right
            .message_block
              .message_block__header
                h2.title Чат
              div.message_block__wrap
                .message(style="overflow-y: auto;", ref="messagesPlaceholder")
                  //.data 12 октября 2021
                  div(v-for="item in history", v-if="item.comment !== ''", :class="item.user !== user.id ? 'message__left' : 'message__right'")
                    .username(style='color: #f2f545;font-size: 11px;font-weight: bold;') {{item.username}}
                    div(style='padding: 0;word-break: break-word;') {{item.comment}}
                    small.time(style='color: #d0d0d0;float: right;') {{item.created_at.replace("T","")}}
                .fiend_set
                  input.field(type='text', v-model="addedComment", v-on:keyup.enter="addComment();")
                  button.button(type='submit', @click="addComment(); ") Отправить



</template>

<script>
import axios from "axios";

export default {
  name: "Partner_Order_CRM",
  data: function () {
    return {
      order: {id: "", status: -1},
      order_user: {id: 0, name: "", _partner: {id: 0, name: ""}},
      order_partner: {id: 0, name: ""},
      history: [],
      addedComment: "",
      isModalVisible: false,
      opts: {
        canAdd: true,
        canEdit: true,
        canRemove: true,
        where: {
          order: this.$route.params.id
        }
      }
    }
  },
  mounted() {

    let that = this;
    window.REST.get("client_orders", {id: that.$route.params.id})
        .then(res => that.order = res.data[0])
        .then(() => window.REST.get('user', {id: that.order.user}))
        .then(res => that.order_user = res.data[0])
        .then(() => window.REST.get('partners', {id: that.order_user.partner}))
        .then(res => that.order_partner = res.data[0])

    that.updateHistory(that.$route.params.id)

    setInterval(() => that.updateHistory(that.$route.params.id), 5000);

  },
  methods: {

    customOpts: function () {
      let opts = this.opts;
      opts.canAdd = ((this.order.status < 4 || this.order.status >=16 && this.order.status<=19) && this.order.status > 0) || this.role === 'BuyManager';
      opts.canRemove = (this.order.status < 4 || this.order.status >=16 && this.order.status<=19) && this.order.status > 0;
      opts.template = {
        table: {
          class: 'table table-hover no-border',
          row: {
            class: 'datatable-edit-link'
          },

          sorting: {
            sortAsc: '<svg width="13px" style="color: gray" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-amount-up-alt" class="svg-inline--fa fa-sort-amount-up-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M240 96h64a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm0 128h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm256 192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-256-64h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zM16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.39-17.24 11.31-27.31l-80-96a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160z"></path></svg>',
            sortDesc: '<svg width="13px" style="color: gray" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-amount-down" class="svg-inline--fa fa-sort-amount-down fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-128-64h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm256-192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>',
            sortNone: '<svg width="8px" style="color: gray" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort" class="svg-inline--fa fa-sort fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>', //'<i class="fas fa-sort" title="Sort"></i>',
          },

        },
        pager: {
          classes: {
            pager: 'pagination text-center',
            li: 'page-item page-link',
            selected: 'active',
          },
          icons: {
            next: '<i class="fas fa-chevron-right" title="Next page"></i>',
            previous: '<i class="fas fa-chevron-left" title="Previous page"></i>',
          },
        },
      }

      return opts;
    },

    getCurrency: function (currency) {
      switch (currency) {
        case 1:
          return 'RUB';
        case 2:
          return 'USD';
        case 3:
          return 'UNY';
      }
    },

    getCountry: function (country) {
      switch (country) {
        case 1:
          return 'Китай';
        case 2:
          return 'Россия';
      }
    },

    columnsupdated: function (cols) {

      // NOTE: более интересный вариант - это if(statuses[current].canEdit.includes(role)), нежели перечисление всех ролей, но это надо всё рефакторить

      let status = this.order.status;
      let role = this.role;

      cols.set('name', {label: "Название", hasFilter: false, isShowOnPopup: true, isEdit:  (status < 4 || status >=16 && this.order.status<=19)});
      cols.delete('order', {
        label: "Номер заявки",
        hasFilter: false,
        isShowOnPopup: false,
        isEdit: false,
        defaultValue: this.order.id
      });
      cols.delete('source_id', {label: "Артикул", hasFilter: false, isShowOnPopup: false, isEdit: false});
      cols.delete('link', {
        label: "Ссылка",
        hasFilter: false,
        isShowOnPopup: true,
        interpolate: true,
        representAs: row => '<a href="' + row.link + '"> ссылка </a>',
        isEdit:  (status < 4 || status >=16 && this.order.status<=19),
      });
      cols.delete('photo_link', {
        label: "Ссылка на фото",
        editName: "Ссылка на фото",
        hasFilter: false,
        isShowOnPopup: false,
        isEdit: false,
      });
      cols.set('photo_blob', {
        label: "Фото",
        editName: "Загрузить фото",
        hasFilter: false,
        isShowOnPopup: true,
        popupType: "image",
        popupImage: {buttons: false, convertTo: 'image/jpeg'},
        interpolate: true,
        representedAs: (row) => {
          return '<img src="'+ row.photo_blob + '" style="max-height: 60px"/>';
        },
        isEdit: (status < 4 || status >=16 && this.order.status<=19),
      });
      cols.delete('color', {label: "Цвет", editName: "Цвет", hasFilter: false, isShowOnPopup: false, isEdit: false});
      cols.set('currency', {
        label: "Валюта",
        editName: "Валюта поставщика",
        hasFilter: false,
        isShowOnPopup: true,
        isEdit: (status < 4 || status >=16 && this.order.status<=19),
        isLoadKeys: true,
        selectRepresentAs: (row) => row.name,
        representedAs: (row) => {
          switch (row.currency) {
            case 1:
              return 'RUB';
            case 2:
              return 'USD';
            case 3:
              return 'CNY';
          }
        },
        // Преобразовать колонку в html: true
        interpolate: true
      });
      cols.set('qt', {
        label: "Количество товара",
        editName: "Количество товара",
        editDesc: "Количество товара",
        hasFilter: false,
        isShowOnPopup: true,
        isEdit:  (status < 4 || status >=16 && this.order.status<=19),
        type: 'number'
      });
      cols.set('summ', {
        label: "Общая сумма",
        editName: "Общая сумма",
        editDesc: "Сумма за всё количество в валюте поставщика",
        hasFilter: false,
        isShowOnPopup: false,
        isEdit: false,
        type: 'number',
        defaultValue: 0,
      });

      cols.delete('id', {label: "Номер товара", hasFilter: false, type: 'fixed', isShowOnPopup: false, isEdit: false});
      cols.delete('user', {label: "ID пользователя", hasFilter: false, type: 'fixed', isShowOnPopup: false, isEdit: false});


      //if (status >= 2 && status<=15 || status >=16 && this.order.status<=30) {
        cols.set('comment_manager', {
          label: "Комментарий менеджера",
          editDesc: "Комментарий отдела сопровождения",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: role === 'Manager' &&  (status < 4 || status >=16 && this.order.status<=19),
          isEdit: role === 'Manager' &&  (status < 4 || status >=16 && this.order.status<=19)
        });
      /*} else {
        cols.delete('comment_manager', {
          label: "Комментарий ОС",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: false,
          isEdit: false
        });
      }*/

      //if (status >= 12 && status < 16 || status >=27) {
        cols.set('comment_fullfill', {
          label: "Комментарий Фуллфилла",
          editDesc: "Комментарий Фуллфилла",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: role === 'Fullfill' && (status === 12 || status===27),
          isEdit: role === 'Fullfill' && (status === 12 || status===27)
        });
      /*} else {
        cols.delete('comment_fullfill', {
          label: "Комментарий Фуллфилла",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: false,
          isEdit: false
        });
      }*/




      cols.set('insurance', {label: 'Страхование (₽ за всю партию)', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('package', {label: 'Упаковка партии товара (₽)', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('sell_price', {label: 'Цена продажи за единицу товара (₽)', editName: "Цена продажи за единицу товара (₽)", type: 'number', isEdit: true, isShowOnPopup: true, hasFilter: false})
      cols.set('fee_mp', {label: 'Комиссия маркетплейса (%)', editName: "Комиссия маркетплейса (%)", isEdit: true, type: 'number', isShowOnPopup: true, hasFilter: false})
      cols.set('transportcosts', {label: 'Транспортировочные и разгрузочно-погрузочные расходы на партию товара (₽)', type: 'number', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('transportcostsinter', {label: 'Международные логистические расходы на партию товара (₽)', type: 'number', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('logisticscosts', {label: 'Логистические расходы по Китаю на партию товара (₽)', type: 'number', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('logistics_costs', {label: 'Логистические расходы по Китаю согласно данным от поставщика (в валюте поставщика), за партию', type: 'number', isEdit: true, isShowOnPopup: true, hasFilter: false})
      cols.set('customsclearance', {label: 'Таможенное оформление партии товара (₽)', type: 'number', isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('yuan_rate', {label: 'Курс Юаня', isEdit: true, isShowOnPopup: true, type: 'number', hasFilter: false})
      cols.set('dollar_rate', {label: 'Курс Доллара', isEdit: true, isShowOnPopup: true, type: 'number', hasFilter: false})
      cols.set('weight', {label: 'Вес товара с упаковкой (в граммах), за ед.', type: 'number', isEdit: true, isShowOnPopup: true, hasFilter: false})

      cols.set('purchase_value', {isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('extra_charge', {isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('income_forecast', {isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('overhead', {isEdit: false, isShowOnPopup: false, hasFilter: false})
      cols.set('marginality', {isEdit: false, isShowOnPopup: false, hasFilter: false})


      // ДЕЛЕНИЕ ПО ТИПУ СТРАНЫ ЗАКАЗА

      // Обычный из Китая
      if(this.order.type === 1) {
        cols.delete('links_mp', {
          label: "",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: false,
          isEdit: false,
        });
        cols.set('price', {
          label: "Закупочная стоимость единицы товара (в валюте поставщика)",
          editName: "Закупочная стоимость единицы товара (в валюте поставщика)",
          editDesc: "Закупочная стоимость единицы товара (в валюте поставщика)",
          hasFilter: false,
          isShowOnPopup: true,
          isEdit: (status < 4 || status >=16 && this.order.status<=19),
          type: 'number'
        });

        cols.delete('local_expectedprice', {isEdit: false, isShowOnPopup: false, hasFilter: false});
        cols.delete('local_expectedsumm', {isEdit: false, isShowOnPopup: false, hasFilter: false});
        cols.delete('local_factprice', {isEdit: false, isShowOnPopup: false, hasFilter: false});
        cols.delete('local_factsumm', {isEdit: false, isShowOnPopup: false, hasFilter: false});
      }

      // Маркетплейсы
      if(this.order.type === 2) {


        cols.set('price', {
          label: "Цена на площадках маркетплейса РФ",
          editName: "Цена на площадках маркетплейса РФ",
          editDesc: "Цена на площадках маркетплейса РФ",
          hasFilter: false,
          isShowOnPopup: true,
          isEdit: (status < 4 || status >=16 && this.order.status<=19),
          type: 'number'
        });
        cols.set('summ', {label: "Общая сумма", defaultValue: 0});

        cols.set('links_mp', {
          label: "Ссылка",
          interpolate: true,
          representAs: row => '<a href="' + row.links_mp + '"> ссылка </a>',
          editName: "Ссылка на пример товара с маркетплейса РФ",
          hasFilter: false,
          type: 'fixed',
          isShowOnPopup: true,
          isEdit: (status < 4 || status >=16 && this.order.status<=19)
        });

        cols.set('local_expectedprice', {label: "Ожидаемая цена закупки", hasFilter: false, type: 'fixed', isShowOnPopup: false, isEdit: false});
        cols.set('local_expectedsumm', {label: "Предварительная итоговая сумма закупки", hasFilter: false, type: 'fixed', isShowOnPopup: false, isEdit: false});
        cols.set('local_factprice', {label: "Цена закупки фактическая", hasFilter: false, type: 'fixed', isShowOnPopup: role === 'BuyManager', isEdit: role === 'BuyManager'});
        cols.set('local_factsumm', {label: "Итоговая сумма закупки", hasFilter: false, type: 'fixed', isShowOnPopup: false, isEdit: false});


        cols.delete('link', {label: "Ссылка(и) на пример товара на маркетплейсах РФ", isShowOnPopup: true, isEdit: (status < 4 || status >=16 && this.order.status<=19)});
        cols.delete('currency', {isShowOnPopup: false, isEdit: false, defaultValue: 1}); // По-умолчанию рубли
        cols.set('price', {label: "Цена на площадках маркетплейса РФ",});

        cols.delete('transportcosts', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('transportcostsinter', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('logisticscosts', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('logistics_costs', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('customsclearance', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('yuan_rate', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('dollar_rate', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('weight', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('sell_price', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('package', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('insurance', {isEdit: false, isShowOnPopup: false, hasFilter: false})

        cols.delete('purchase_value', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('extra_charge', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('income_forecast', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('overhead', {isEdit: false, isShowOnPopup: false, hasFilter: false})
        cols.delete('marginality', {isEdit: false, isShowOnPopup: false, hasFilter: false})

      }

    },

    addComment: function (custom_status = null) {
      let that = this;

      // Меняем статусы
      let status = custom_status === null ? that.order.status : custom_status;
      //if(that.order.status === 2 && that.role === 'Manager') status = 3;

      if (that.addedComment.trim() === '' && status === that.order.status) return;

      if (status !== that.order.status) {
        window.REST.edit("client_orders", that.order.id, {status: status}).then(res => that.order = res.data);
      }

      window.REST.create("client_order_history", {
        order: that.order.id,
        comment: that.addedComment,
        status: status,
        username: this.$store.state.User.username
      })
          .then(() => that.updateHistory(that.order.id)).then(() => that.addedComment = "");
    },

    getStatus: function (id) {
      return this.$store.state.statuses.find(res => res.id === id)?.name;
    },
    updateHistory: function (orderID) {
      let that = this;
      // Сразу с сортировкой
      window.REST.get("client_order_history", {order: orderID}, null, null, ['created_at']).then(res => {
        that.history = res.data;
        setTimeout(() => {
          that.$refs.messagesPlaceholder.scrollTop = that.$refs.messagesPlaceholder.scrollHeight;
        }, 500)
      })
    },

    exportTo() {
      var country = 'Китай';
        if(this.order.type === 2) {
          country = 'Россия';
        }
      this.$refs.maintable.export('xlsx', this.order_partner.name + "_" + country + "_" + this.order.id);
    },

    uploadFile: function (event) {
      event.preventDefault();
      let that = this;

      let formData = new FormData();
      let excel_file = document.getElementById("fileUpload");

      formData.append("order", that.order.id);
      formData.append("excel", excel_file.files[0]);

      axios.post('/api/clientordergoods/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        let data = response.data;
        console.log(data);
        //if(data.success){
        alert(data.success ? data.success : 'Успешно');
        /*}else{
          alert(data.error ? data.error : 'Произошла ошибка');
        }*/
      }).catch(function (error) {
        console.log(error);
      });

    },

    exportFile: function (event) {
      event.preventDefault();
      let that = this;

      let formData = new FormData();

      formData.append("order", that.order.id);

      axios.post('/api/clientordergoods/export', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        let data = response.data;
        console.log(data);
      }).catch(function (error) {
        console.log(error);
      });

    }
  },

  computed: {
    role: function () {
      return this.$store.state.User.role
    },
    user: function () {
      return this.$store.state.User
    },
    statuses: function () {
      return this.$store.state.statuses.filter(r=>r.type === this.order.type);
    },
    histSorted: function () {
    }
  }

}
</script>

<style lang="scss">

// ВНУТРИ ЭТОГО БЛОКА СТИЛИ ТОЛЬКО ДЛЯ ЭТОЙ СТРАНИЦЫ
.PartnerOrderCRM {

  button {
    outline: none !important;
    border: none;
  }

  .input-group-prepend{
    width: 50%;
  }
  .input-group-text{
    width: 100% !important;
    max-width: 100% !important;
  }
  .datatable-modal-container{
    width: 100% !important;
  }

  .wrap_table .crudtable {
    margin-top: 8px !important;
    background-color: #F5F5F5;
    border-radius: 1.1875rem;
    padding: 1.625rem 2rem 3.4375rem 2rem;
    font-size: 12px;
  }

  .wrap_table .crudtable thead th span {
    font-size: 10px;
  }

  .table td, .table th {
    padding: .5rem !important;
  }

  .crudtable {
    .datatable-modal-body {
      .mb-3 {
        margin: 0;
      }
    }
  }

  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

  .relative {
    position: relative;
  }

  .w-8\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .w-7\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .w-6\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .w-5\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .w-4\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100%;
  }

  .block {
    display: block;
  }

  .flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-end {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .flex-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .items-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .items-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .container {
    background-color: red;
  }

  .button_green {
    width: 14rem;
    height: 2.875rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0.8125rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#27E78C), to(#4DC18A));
    background: -o-linear-gradient(top, #27E78C 0%, #4DC18A 100%);
    background: linear-gradient(180deg, #27E78C 0%, #4DC18A 100%);
  }

  .button_green:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#4DC18A), to(#27E78C));
    background: -o-linear-gradient(top, #4DC18A 0%, #27E78C 100%);
    background: linear-gradient(180deg, #4DC18A 0%, #27E78C 100%);
  }

  .button_green span {
    font-weight: bold;
    color: white;
  }

  .button_green_border {
    position: relative;
    width: 14rem;
    height: 2.875rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0.8125rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#27E78C), to(#4DC18A));
    background: -o-linear-gradient(top, #27E78C 0%, #4DC18A 100%);
    background: linear-gradient(180deg, #27E78C 0%, #4DC18A 100%);
  }

  .button_green_border::before {
    content: "";
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    background-color: white;
    border-radius: 0.6875rem;
    -webkit-transition: 250ms ease;
    -o-transition: 250ms ease;
    transition: 250ms ease;
  }

  .button_green_border:hover::before {
    opacity: 0;
  }

  .button_green_border:hover span {
    color: white;
  }

  .button_green_border span {
    position: relative;
    color: #4DC18A;
    z-index: 2;
  }

  .text-red {
    color: #DA3A3A;
  }

  .wrap_box {
    //background-color: #E5E5E5;
    //padding: 2.625rem 2.5rem 1.875rem 2.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    .wrap_box {
      padding: 1.3125rem 1.25rem 0.9375rem 1.25rem;
    }
  }

  .wrap_box .wrap_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  .wrap_box .wrap_header .title {
    font-weight: bold;
    font-size: 2.25rem;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header .title {
      margin-bottom: 1.25rem;
    }
  }

  @media (max-width: 1050px) {
    .wrap_box .wrap_header .title {
      font-size: 1.5rem;
    }
  }

  .wrap_box .wrap_header__links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header__links {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .wrap_box .wrap_header__links .button_green {
    margin-right: 1rem;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header__links .button_green {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .wrap_box .wrap_header__links .button_green_border {
    margin-right: 1rem;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header__links .button_green_border {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .wrap_box .wrap_header__links .button_green_border:last-child {
    margin-right: 0;
  }

  @media (max-width: 1200px) {
    .wrap_box .wrap_header__links .button_green_border:last-child {
      margin-bottom: 0;
    }
  }

  .wrap_box .wrap_content {
    border-radius: 0.5rem;
    background-color: white;
    padding: 1.625rem 1rem;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content {
      padding: 0.8125rem 0.5rem;
    }
  }

  .wrap_box .wrap_content__grafic {
    position: relative;
    margin: 2rem 1.375rem 5rem 1.375rem;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content__grafic {
      margin: 1rem 0.6875rem 2.5rem 0.6875rem;
    }
  }

  .wrap_box .wrap_content__grafic .line {
    position: relative;
    z-index: 2;
    background-color: #DFDFDF;
    -webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.9375rem;
    height: 1.625rem;
  }

  .wrap_box .wrap_content__grafic .line_active {
    height: 100%;
    border-radius: 0.9375rem;
    background: -webkit-gradient(linear, left top, right top, from(#F2F545), color-stop(96.26%, #4DC18A));
    background: -o-linear-gradient(left, #F2F545 0%, #4DC18A 96.26%);
    background: linear-gradient(90deg, #F2F545 0%, #4DC18A 96.26%);
    -webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
  }

  .wrap_box .wrap_content__grafic .line_active::before {
    content: "";
    position: absolute;
    top: 0.125rem;
    right: -0.125rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#E8E8E8));
    background: -o-linear-gradient(top, #FFFFFF 0%, #E8E8E8 100%);
    background: linear-gradient(180deg, #FFFFFF 0%, #E8E8E8 100%);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
  }

  .wrap_box .wrap_content__grafic .steps {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: -2.25rem;
    left: 0;
    height: 6.25rem;
    width: 100%;
  }

  .wrap_box .wrap_content__grafic .steps > div {
    position: relative;
    width: 6.6%;
    height: 3rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content__grafic .steps > div {
      width: 6.5%;
    }
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__grafic .steps > div {
      opacity: 0;
    }
  }

  .wrap_box .wrap_content__grafic .steps > div:nth-child(2n) {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrap_box .wrap_content__grafic .steps > div span {
    font-size: 0.75rem;
    color: #C6C6C6;
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content__grafic .steps > div span {
      font-size: 0.5rem;
    }
  }

  .wrap_box .wrap_content__grafic .steps > div::before {
    content: "";
    height: 1.625rem;
    width: 0.125rem;
    display: block;
    background-color: #ABABAB;
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__grafic .steps > div.active {
      opacity: 1;
    }
  }

  .wrap_box .wrap_content__grafic .steps > div.active::before {
    background-color: #459315;
  }

  .wrap_box .wrap_content__grafic .steps > div.active span {
    background-color: #459315;
    padding: 0.1875rem 0.625rem;
    border-radius: 1.0625rem;
    color: white;
    font-weight: bold;
  }

  .wrap_box .wrap_content__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 1.75rem;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content__row {
      padding: 0 0.875rem;
    }
  }

  .wrap_box .wrap_content__row .left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(65% - 40px);
    -ms-flex: 0 0 calc(65% - 40px);
    flex: 0 0 calc(65% - 40px);
    max-width: calc(65% - 40px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  @media (max-width: 1320px) {
    .wrap_box .wrap_content__row .left {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1.25rem;
    }
  }

  .wrap_box .wrap_content__row .wrap_table, {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.75rem;
  }

  .wrap_box .wrap_content__row .wrap_table__header {
    margin-bottom: 1.375rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .wrap_table__header {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  .wrap_box .wrap_content__row .wrap_table__header .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4F4F4F;
  }

  @media (max-width: 1050px) {
    .wrap_box .wrap_content__row .wrap_table__header .title {
      font-size: 1.125rem;
    }
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .wrap_table__header .title {
      margin-bottom: 0.875rem;
    }
  }

  .AddButton {
    padding: 0.625rem 1.375rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#27E78C), to(#4DC18A));
    background: -o-linear-gradient(top, #27E78C 0%, #4DC18A 100%);
    background: linear-gradient(180deg, #27E78C 0%, #4DC18A 100%);
    -webkit-box-shadow: 0px 5px 13px rgba(163, 163, 163, 0.3);
    box-shadow: 0px 5px 13px rgba(163, 163, 163, 0.3);
    border-radius: 34px;
  }

  @media (max-width: 1050px) {
    .AddButton {
      padding: 0.375rem 1rem;
    }
  }

  .AddButton:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#4DC18A), to(#27E78C));
    background: -o-linear-gradient(top, #4DC18A 0%, #27E78C 100%);
    background: linear-gradient(180deg, #4DC18A 0%, #27E78C 100%);
  }

  .AddButton span {
    font-size: 1.125rem;
    color: white;
    font-weight: bold;
  }

  @media (max-width: 1050px) {
    .AddButton span {
      font-size: 0.875rem;
    }
  }

  .wrap_box .wrap_content__row .wrap_table__box {
    background-color: #F5F5F5;
    border-radius: 1.1875rem;
    padding: 1.625rem 2rem 1.625rem 2rem;
  }

  @media (max-width: 1050px) {
    .wrap_box .wrap_content__row .wrap_table__box {
      padding: 0.8125rem 1rem 1.6875rem 1rem;
      overflow-x: auto;
    }
  }

  .wrap_box .wrap_content__row .wrap_table__box .items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    .wrap_box .wrap_content__row .wrap_table__box .items {
      width: 63.75rem;
    }
  }

  .wrap_box .wrap_content__row .wrap_table__box .items .item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.75rem;
  }

  .wrap_box .wrap_content__row .wrap_table__box .items .item:first-child {
    margin-bottom: 0.9375rem;
  }

  .wrap_box .wrap_content__row .wrap_table__box .items .item:not(:first-child) {
    padding: 0.75rem 0;
    border-bottom: 0.125rem solid white;
  }

  .wrap_box .wrap_content__row .wrap_table__box .items .item b, .wrap_box .wrap_content__row .wrap_table__box .items .item span {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 14.2%;
    -ms-flex: 0 0 14.2%;
    flex: 0 0 14.2%;
    max-width: 14.2%;
  }

  .wrap_box .wrap_content__row .wrap_table__box .items .item b {
    font-size: 0.8125rem;
  }

  .wrap_box .wrap_content__row .wrap_table__box .list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
  }

  .wrap_box .wrap_content__row .wrap_table__box .list li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .wrap_box .wrap_content__row .wrap_table__box .list li:last-child {
    margin-bottom: 0;
  }

  .wrap_box .wrap_content__row .wrap_table__box .list li span:first-child {
    color: #4F4F4F;
  }

  .wrap_box .wrap_content__row .wrap_table__box .list li span:last-child {
    font-weight: bold;
    font-size: 1.125rem;
  }

  @media (max-width: 899px) {
    .wrap_box .wrap_content__row .wrap_table__box .list li span:last-child {
      font-size: 1rem;
    }
  }

  .wrap_box .wrap_content__row .right {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 35%;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (max-width: 1320px) {
    .wrap_box .wrap_content__row .right {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .wrap_box .wrap_content__row .right .message_block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrap_box .wrap_content__row .right .message_block__header {
    margin-bottom: 1.375rem;
  }

  .wrap_box .wrap_content__row .right .message_block__header .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #4F4F4F;
  }

  @media (max-width: 1050px) {
    .wrap_box .wrap_content__row .right .message_block__header .title {
      font-size: 1.125rem;
    }
  }

  .wrap_box .wrap_content__row .right .message_block__wrap {
    position: relative;
    height: 37.6875rem;
    background-color: #F5F5F5;
    border-radius: 1.1875rem;
    padding: 1.875rem 1.125rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .data {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #808080;
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .right .message_block__wrap .data {
      font-size: 0.75rem;
    }
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .message {
    margin-top: auto;
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .message__left, .wrap_box .wrap_content__row .right .message_block__wrap .message__right {
    margin-bottom: 0.625rem;
    border-radius: 0.6875rem;
    padding: 0.5rem 0.6875rem;
    color: white;
    width: auto;
    max-width: 45%;
    min-width: 30%;
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .right .message_block__wrap .message__left, .wrap_box .wrap_content__row .right .message_block__wrap .message__right {
      font-size: 0.75rem;
    }
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .message__left {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    background-color: #29C079;
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .message__right {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: #8DA499;
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set {
    position: relative;
    border-radius: 0.6875rem;
    background-color: white;
    margin-top: 10px;
    input {
      border: none;
    }
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set .field {
    background-color: transparent;
    font-size: 0.875rem;
    color: #808080;
    height: 100%;
    width: 100%;
    padding: 10px;
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set .field {
      padding: 0.125rem 6.25rem 0.125rem 0.625rem;
    }
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set .button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(#27E78C), to(#4DC18A));
    background: -o-linear-gradient(top, #27E78C 0%, #4DC18A 100%);
    background: linear-gradient(180deg, #27E78C 0%, #4DC18A 100%);
    border-radius: 0.6875rem;
    padding: 0 1.1875rem;
    font-size: 0.75rem;
    color: white;
    font-weight: bold;
  }

  .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set .button:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#4DC18A), to(#27E78C));
    background: -o-linear-gradient(top, #4DC18A 0%, #27E78C 100%);
    background: linear-gradient(180deg, #4DC18A 0%, #27E78C 100%);
  }

  @media (max-width: 540px) {
    .wrap_box .wrap_content__row .right .message_block__wrap .fiend_set .button {
      padding: 0 0.875rem;
      font-size: 0.75rem;
    }
  }

  .fx-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .fx-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc((100% - 40px));
    -ms-flex: 0 0 calc((100% - 40px));
    flex: 0 0 calc((100% - 40px));
    max-width: calc((100% - 40px));
    margin-right: 2.5rem;
  }

  .fx-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc((100% - 40px) / 2);
    -ms-flex: 0 0 calc((100% - 40px) / 2);
    flex: 0 0 calc((100% - 40px) / 2);
    max-width: calc((100% - 40px) / 2);
    margin-right: 2.5rem;
  }

  .fx-5:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    .fx-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }

  .crudtable .btn-primary {
    padding: 0.625rem 1.375rem;
    background: -o-linear-gradient(top, #27E78C 0%, #4DC18A 100%);
    background: linear-gradient(
            180deg, #27E78C 0%, #4DC18A 100%);
    -webkit-box-shadow: 0px 5px 13px rgb(163 163 163 / 30%);
    box-shadow: 0px 5px 13px rgb(163 163 163 / 30%);
    border-radius: 34px;
    border: 0;
    font-size: 1.125rem;
    color: white;
    font-weight: bold;
  }

  div[flamecrud] {
    margin: 0;
  }

}

// ТУТ СТИЛИ ОБЩИЕ ДЛЯ ВСЕГО ПРОЕКТА МОЖНО НАСТРОИТЬ

#popupWindow{
  z-index: 999999;
  overflow: auto;
}

</style>
